import { useState, useRef, useCallback } from 'react'
import Layout from '../../components/Layout'
import ProjectSideBar from './ProjectSideBar'
import SelectPanel from './SelectPanel'
import InspectPanel from './InspectPanel'
import Tools from './Tools'
import { LayersContext, FeaturesContext, ToolbarContext } from '../../context'
import { useLayersData, useFeaturesData } from '../../hooks'
import { Map, Zoom, View } from 'geol'
import S from './project.module.scss'
import Layer from './Layer'
import MapHandle from './MapHandle'
import find from 'lodash/find'
import { Dialog } from 'primereact/dialog'
import FormWrapper from './FormWrapper'
import { v4 as uuidv4 } from 'uuid'

function AddLayerDialog({ layerOptions, stopAddLayer, addLayer }) {
  const { type, options } = layerOptions || {}

  return (
    <Dialog visible={!!layerOptions} onHide={stopAddLayer} header={'Add layer'}>
      {!!layerOptions && (
        <FormWrapper
          type={type}
          options={options}
          onSubmit={(values) => {
            addLayer({ type, options: values, id: uuidv4() })
            stopAddLayer()
          }}
        ></FormWrapper>
      )}
    </Dialog>
  )
}

export default function Project() {
  const {
    layers,
    addLayer,
    setLayers,
    updateLayer,
    removeLayer,
    toggleLayerVisible,
  } = useLayersData()

  const { features, setFeatures } = useFeaturesData()

  const [currentTool, setCurrentTool] = useState('select')
  const [currentLayerId, setCurrentLayerId] = useState(null)
  const [selectedLayer, setSelectedLayer] = useState(null)

  const setSelectedLayerFromId = useCallback((layerId) => {
    const layers = mapRef.current.getLayers().getArray()
    const layer = find(layers, (item) => item.getProperties().id === layerId)
    if (!layer || !layer.getSource) {
      return
    }
    setSelectedLayer(layer)
  }, [])

  const setFeaturesFromLayer = useCallback(
    (layerId) => {
      const layers = mapRef.current.getLayers().getArray()
      const layer = find(layers, (item) => item.getProperties().id === layerId)
      if (!layer || !layer.getSource) {
        return
      }
      try {
        const f = layer.getSource().getFeatures()
        setFeatures(f)
      } catch (err) {}
    },
    [setFeatures]
  )

  const toggleSelectedLayerFromId = useCallback(
    (layerId) => {
      if (layerId === currentLayerId) {
        setCurrentLayerId(null)
        setSelectedLayer(null)
      } else {
        setCurrentLayerId(layerId)
        setSelectedLayerFromId(layerId)
      }
    },
    [currentLayerId, setSelectedLayerFromId]
  )

  const [currentAddLayer, setCurrentAddLayer] = useState(null)
  const stopAddLayer = useCallback(() => setCurrentAddLayer(null), [])

  const mapRef = useRef()

  const centerLayer = useCallback((layerId) => {
    const layers = mapRef.current.getLayers().getArray()
    const layer = find(layers, (item) => item.getProperties().id === layerId)
    if (!layer) {
      return
    }
    const source = layer.getSource()
    const view = mapRef.current.getView()
    if (!source || !source.getExtent) {
      view.setZoom(view.getMinZoom())
      return
    }

    view.fit(source.getExtent())
  }, [])

  const addLayerWithDialog = useCallback(({ type, options }) => {
    setCurrentAddLayer({ type, options })
  }, [])

  return (
    <ToolbarContext.Provider value={{ currentTool, setCurrentTool }}>
      <LayersContext.Provider
        value={{
          layers,
          addLayer,
          setLayers,
          updateLayer,
          removeLayer,
          toggleLayerVisible,
          centerLayer,
          addLayerWithDialog,
          currentLayerId,
          toggleSelectedLayerFromId,
          selectedLayer,
        }}
      >
        <FeaturesContext.Provider value={{ features, setFeatures }}>
          <Layout>
            <Tools />
            <div className="body-container">
              <ProjectSideBar></ProjectSideBar>
              <div className="flex-1">
                <Map style={{ width: '100%', height: '100%' }}>
                  <MapHandle ref={mapRef}></MapHandle>
                  <Zoom />
                  <View
                    initialCenter={[9.716667, 45.683333]}
                    zoom={10}
                    inProj="EPSG:4326"
                    outProj="EPSG:3857"
                  />
                  {layers.map((layer, i) => (
                    <Layer
                      id={layer.id}
                      key={layer.id}
                      type={layer.type}
                      options={{ ...layer.options, zIndex: i }}
                    ></Layer>
                  ))}
                </Map>
              </div>
              <div className={`${S['right-content']}`}>
                {currentTool === 'select' && (
                  <SelectPanel features={features} />
                )}
                {currentTool === 'inspect' && <InspectPanel />}
              </div>
            </div>
            <AddLayerDialog
              layerOptions={currentAddLayer}
              addLayer={addLayer}
              stopAddLayer={stopAddLayer}
            ></AddLayerDialog>
          </Layout>
        </FeaturesContext.Provider>
      </LayersContext.Provider>
    </ToolbarContext.Provider>
  )
}
