import { useImperativeHandle, forwardRef } from 'react'
import { useMap } from 'geol'

function MapHandle(props, ref) {
  const map = useMap()

  useImperativeHandle(ref, () => map)
  return null
}

export default forwardRef(MapHandle)
