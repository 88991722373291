import { TileLayer, StamenSource } from 'geol'

export default function StamenLayer({
  id,
  opacity,
  visible = true,
  layer = 'stamen',
}) {
  return (
    <TileLayer opacity={opacity} visible={visible} id={id}>
      <StamenSource layer={layer} />
    </TileLayer>
  )
}
