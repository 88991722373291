import React, { memo } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { SplitButton } from 'primereact/splitbutton'
import { ToggleButton } from 'primereact/togglebutton'
import { useToolbar } from '../../hooks'

function Tools() {
  const { currentTool, setCurrentTool } = useToolbar()

  // const items = [
  //   {
  //     label: 'Update',
  //     icon: 'pi pi-refresh',
  //   },
  //   {
  //     label: 'Delete',
  //     icon: 'pi pi-times',
  //   },
  //   {
  //     label: 'React Website',
  //     icon: 'pi pi-external-link',
  //     command: () => {
  //       window.location.href = 'https://reactjs.org/'
  //     },
  //   },
  //   {
  //     label: 'Upload',
  //     icon: 'pi pi-upload',
  //     command: () => {
  //       window.location.hash = '/fileupload'
  //     },
  //   },
  // ]

  const rightContents = (
    <React.Fragment>
      <ToggleButton
        onIcon="pi pi-pencil"
        onLabel=""
        offLabel=""
        offIcon="pi pi-pencil"
        className="p-mr-2"
        checked={currentTool === 'select'}
        onChange={() => {
          if (currentTool === 'select') {
            setCurrentTool(null)
          } else {
            setCurrentTool('select')
          }
        }}
      />
      <ToggleButton
        onIcon="pi pi-table"
        onLabel=""
        offLabel=""
        offIcon="pi pi-table"
        className="p-mr-2"
        checked={currentTool === 'inspect'}
        onChange={() => {
          if (currentTool === 'inspect') {
            setCurrentTool(null)
          } else {
            setCurrentTool('inspect')
          }
        }}
      />
      {/* <Button icon="pi pi-calendar" className="p-button-success p-mr-2" />
      <Button icon="pi pi-times" className="p-button-danger" /> */}
    </React.Fragment>
  )

  return (
    <div>
      <Toolbar right={rightContents} />
    </div>
  )
}

export default memo(Tools)
