import S from './project.module.scss'
import LayersPanel from './LayersPanel'

export default function ProjectSideBar() {
  return (
    <div className={S['sidebar']}>
      <LayersPanel></LayersPanel>
    </div>
  )
}
