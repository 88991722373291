import { Slider } from 'primereact/slider'
import { InputText } from 'primereact/inputtext'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Formik } from 'formik'
import isPlainObject from 'lodash/isPlainObject'

function GenericField({ name, prefix, value, handleChange }) {
  let parsedValue
  try {
    parsedValue = JSON.parse(value)
  } catch (err) {
    parsedValue = value
  }

  const attrName = prefix ? prefix + '.' + name : name

  return isPlainObject(parsedValue) ? (
    <GenericForm properties={parsedValue} prefix={name}></GenericForm>
  ) : (
    <InputText
      disabled
      name={attrName}
      id={attrName}
      value={value}
      onChange={handleChange}
    ></InputText>
  )
}

function GenericForm({ properties = {}, handleChange, prefix = '' }) {
  const names = Object.keys(properties)
  return names.map((name) => (
    <div key={name}>
      <h5>{name}</h5>
      <GenericField
        name={name}
        prefix={prefix}
        value={properties[name]}
        handleChange={handleChange}
      ></GenericField>
    </div>
  ))
}

export default function FeatureForm({ onSubmit, featureProperties }) {
  return (
    <Formik initialValues={featureProperties} onSubmit={onSubmit}>
      {({
        values,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        handleChange,
        /* and other goodies */
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
            setSubmitting(false)
          }}
        >
          <GenericForm
            properties={featureProperties}
            handleChange={handleChange}
          ></GenericForm>

          <hr />

          <div className="p-mt-3">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="p-button-sm"
            >
              OK
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
