import { Slider } from 'primereact/slider'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { FileUpload } from 'primereact/fileupload'
import { Button } from 'primereact/button'
import { Formik } from 'formik'

export function OSMForm({ onSubmit, initialValues }) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        values,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
            setSubmitting(false)
          }}
        >
          <div className="card">
            <h5>Opacity: {values.opacity}</h5>
            <Slider
              name="opacity"
              value={values.opacity * 100}
              onChange={(e) => {
                setFieldValue('opacity', e.value / 100)
              }}
              step={1}
              min={0}
              max={100}
            />
          </div>
          <div className="p-mt-3">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="p-button-sm"
            >
              OK
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export function StamenForm({ onSubmit, initialValues }) {
  const layerValues = [
    { label: 'toner', value: 'toner' },
    { label: 'watercolor', value: 'watercolor' },
    { label: 'terrain', value: 'terrain' },
  ]

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        values,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
            setSubmitting(false)
          }}
        >
          <div className="card">
            <h5>Layer</h5>
            <Dropdown
              value={values.layer}
              options={layerValues}
              onChange={(e) => setFieldValue('layer', e.value)}
              placeholder="Select a layer"
            />

            <h5>Opacity: {values.opacity}</h5>
            <Slider
              name="opacity"
              value={values.opacity * 100}
              onChange={(e) => {
                setFieldValue('opacity', e.value / 100)
              }}
              step={1}
              min={0}
              max={100}
            />
          </div>
          <div className="p-mt-3">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="p-button-sm"
            >
              OK
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export function GeoJSONForm({ onSubmit, initialValues }) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form
          className="card"
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
            setSubmitting(false)
          }}
        >
          <div>
            <h5>Opacity: {values.opacity}</h5>
            <Slider
              name="opacity"
              value={values.opacity * 100}
              onChange={(e) => {
                setFieldValue('opacity', e.value / 100)
              }}
              step={1}
              min={0}
              max={100}
            />

            <h5>Url</h5>
            <InputText
              id="url"
              value={values.url}
              onChange={(e) => handleChange(e)}
            />

            <h5>File</h5>
            <small>{values.file && values.file.name}</small>
            <FileUpload
              mode="basic"
              customUpload
              onSelect={(value) => {
                const file = value.files[0]
                setFieldValue('file', file)
              }}
            />
          </div>

          <div className="p-mt-3">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="p-button-sm"
            >
              OK
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}


export function ShapefileForm({ onSubmit, initialValues }) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({
        values,
        handleSubmit,
        handleChange,
        isSubmitting,
        setSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <form
          className="card"
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit()
            setSubmitting(false)
          }}
        >
          <div>
            <h5>Opacity: {values.opacity}</h5>
            <Slider
              name="opacity"
              value={values.opacity * 100}
              onChange={(e) => {
                setFieldValue('opacity', e.value / 100)
              }}
              step={1}
              min={0}
              max={100}
            />

            <h5>Url</h5>
            <InputText
              id="url"
              value={values.url}
              onChange={(e) => handleChange(e)}
            />

            <h5>File</h5>
            <small>{values.file && values.file.name}</small>
            <FileUpload
              mode="basic"
              accept="zip"
              customUpload
              onSelect={(value) => {
                const file = value.files[0]
                setFieldValue('file', file)
              }}
            />
          </div>

          <div className="p-mt-3">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="p-button-sm"
            >
              OK
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}