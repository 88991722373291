import LAYERS_TYPES from './LayersTypes'

export default function Layer({ id, type, options = {} }) {
  const Component = LAYERS_TYPES[type].component
  return (
    <>
      <Component {...options} id={id}></Component>
    </>
  )
}
