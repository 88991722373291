import { useContext, useState } from 'react'
import { LayersContext, FeaturesContext, ToolbarContext } from './context'
import findIndex from 'lodash/findIndex'

export function useLayers() {
  return useContext(LayersContext)
}

export function useFeatures() {
  return useContext(FeaturesContext)
}

export function useToolbar() {
  return useContext(ToolbarContext)
}

export function useLayersData() {
  const [layers, setLayers] = useState([])
  const addLayer = (layer) => {
    setLayers(layers.concat([layer]))
  }

  const updateLayer = (layerId, options) => {
    const newLayers = [...layers]
    const layerIndex = findIndex(newLayers, (item) => item.id === layerId)
    newLayers[layerIndex] = {
      ...newLayers[layerIndex],
      options,
    }
    setLayers(newLayers)
  }

  const toggleLayerVisible = (layerId) => {
    const newLayers = [...layers]
    const layerIndex = findIndex(newLayers, (item) => item.id === layerId)
    newLayers[layerIndex] = {
      ...newLayers[layerIndex],
      options: {
        ...newLayers[layerIndex].options,
        visible: !!!newLayers[layerIndex].options.visible,
      },
    }
    setLayers(newLayers)
  }

  const removeLayer = (layerId) => {
    const newLayers = layers.filter((item) => item.id !== layerId)
    setLayers(newLayers)
  }
  return {
    layers,
    setLayers,
    addLayer,
    updateLayer,
    toggleLayerVisible,
    removeLayer,
  }
}

export function useFeaturesData() {
  const [features, setFeatures] = useState([])

  return { features, setFeatures }
}
