import Project from './pages/Project/Project'

function App() {
  return (
    <div className="main-container">
      <Project></Project>
    </div>
  )
}

export default App
