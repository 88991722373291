import S from './project.module.scss'
import omit from 'lodash/omit'
import { useMemo } from 'react'
import FeatureForm from '../../components/FeatureForm'

export default function FeatureDisplay({ feature }) {
  const properties = useMemo(() => {
    if (!feature) {
      return {}
    }
    return omit(feature.getProperties(), 'geometry')
  }, [feature])
  
  return (
    <div
      key={feature.ol_uid}
      className={`card p-p-2 p-m-2 p-shadow-2 ${S['feature-display-card']}`}
    >
      {feature && <FeatureForm featureProperties={properties}></FeatureForm>}
    </div>
  )
}
