import LAYERS_TYPES from './LayersTypes'

export default function FormWrapper({ type, options, onSubmit }) {
    const Component = LAYERS_TYPES[type].formComponent
    const label = LAYERS_TYPES[type].label
    return (
      <div className="card p-p-2" style={{ minWidth: 200 }}>
        <h3>{label}</h3>
        <Component initialValues={options} onSubmit={onSubmit}></Component>
      </div>
    )
  }