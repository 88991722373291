import FeatureDisplay from './FeatureDisplay'
import S from './project.module.scss'


export default function SelectPanel({features}){

    return <div className={`${S['right-container']}`}>
    {features.map((feature) => (
      <FeatureDisplay
        key={feature.ol_uid}
        feature={feature}
      ></FeatureDisplay>
    ))}
  </div>
}