import { OSMForm, StamenForm, GeoJSONForm, ShapefileForm } from './LayerForms'
import OSMLayer from '../../components/OSMLayer'
import GeoJSONLayer from '../../components/GeoJSONLayer'
import ShapeLayer from '../../components/ShapeLayer'
import StamenLayer from '../../components/StamenLayer'

const LAYERS_TYPES = {
  osm: {
    label: 'OSM',
    component: OSMLayer,
    formComponent: OSMForm,
  },
  stamen: {
    label: 'Stamen',
    component: StamenLayer,
    formComponent: StamenForm,
  },
  geojson: {
    label: 'GeoJSON',
    component: GeoJSONLayer,
    formComponent: GeoJSONForm,
  },
  shp: {
    label: 'Shapefile',
    component: ShapeLayer,
    formComponent: ShapefileForm,
  },

}

export default LAYERS_TYPES
