import { memo } from 'react'
import { Menubar } from 'primereact/menubar'

const items = [
  {
    label: 'GeoPad',
    // icon: "pi pi-fw pi-power-off",
  },
]

function TopBar() {
  return (
    <Menubar
      model={items}
      start={<span></span>}
      style={{ backgroundColor: 'var(--cyan-400)' }}
      className="p-shadow-2 header-container"
    />
  )
}

export default memo(TopBar)
