import { useState, useMemo } from 'react'
import S from './project.module.scss'
import { useLayers } from '../../hooks'
import { useMap } from 'geol'
import { Message } from 'primereact/message'
import GeoJSON from 'ol/format/GeoJSON'
import { TabView, TabPanel } from 'primereact/tabview'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-github'

export default function InspectPanel() {
  const map = useMap()
  const featureProjection = useMemo(() => {
    return map?.getView().getProjection()
  }, [map])

  const { selectedLayer } = useLayers()

  const features = useMemo(() => {
    if (!selectedLayer) {
      return []
    }
    try {
      const f = selectedLayer.getSource().getFeatures()
      return f
    } catch (err) {
      return []
    }
  }, [selectedLayer])

  const geo = useMemo(() => {
    const geoString = new GeoJSON().writeFeatures(features, {
      dataProjection: 'EPSG:4326',
      featureProjection,
    })
    return JSON.stringify(JSON.parse(geoString), null, '\t')
  }, [featureProjection, features])

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className={`${S['right-container']}`}>
      {!selectedLayer && (
        <div className="p-p-4">
          <Message
            severity="info"
            text="Please Select a layer"
            className="w-100"
          ></Message>
        </div>
      )}
      {selectedLayer && (
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="GeoJSON">
            <AceEditor
              mode="json"
              theme="github"
              value={geo}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true }}
            />
          </TabPanel>
          <TabPanel header="Table">Table</TabPanel>
        </TabView>
      )}
    </div>
  )
}
