import { useEffect, useState, useMemo } from 'react'
import { useFeatures, useToolbar } from '../hooks'
import { useMap } from 'geol'
import GeoJSON from 'ol/format/GeoJSON'
import { VectorLayer, VectorSource, FeatureSelect } from 'geol'

export default function GeoJSONLayer({
  opacity,
  visible = true,
  url,
  file,
  id,
}) {
  const [initialFeatures, setInitialFeatures] = useState()

  //global app features
  const { features, setFeatures } = useFeatures()

  const map = useMap()
  const featureProjection = useMemo(() => {
    return map?.getView().getProjection()
  }, [map])

  const { currentTool } = useToolbar()

  useEffect(() => {
    if (!url) {
      return
    }
    fetch(url)
      .then((r) => r.json())
      .then((json) => {
        const features = new GeoJSON().readFeatures(json, {
          dataProjection: 'EPSG:4326',
          featureProjection,
        })
        setInitialFeatures(features)
      })
  }, [featureProjection, url])

  useEffect(() => {
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.addEventListener(
      'load',
      function () {
        const dataString = reader.result
        const json = JSON.parse(dataString)
        const features = new GeoJSON().readFeatures(json, {
          dataProjection: 'EPSG:4326',
          featureProjection,
        })
        setInitialFeatures(features)
      },
      false
    )
    reader.readAsText(file)
  }, [featureProjection, file])

  return initialFeatures ? (
    <VectorLayer opacity={opacity} visible={visible} id={id}>
      <VectorSource initialFeatures={initialFeatures}>
        {currentTool === 'select' && (
          <FeatureSelect
            onSelect={(e) => {
              console.log(e)
              setFeatures(e.selected)
            }}
          ></FeatureSelect>
        )}
      </VectorSource>
    </VectorLayer>
  ) : null
}
