import { Button } from 'primereact/button'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useLayers, useToolbar } from '../../hooks'
import { v4 as uuidv4 } from 'uuid'
import { confirmPopup } from 'primereact/confirmpopup'
import FormWrapper from './FormWrapper'
import S from './project.module.scss'

export default function LayersPanel() {
  const {
    layers,
    addLayer,
    setLayers,
    updateLayer,
    removeLayer,
    toggleLayerVisible,
    centerLayer,
    addLayerWithDialog,
    toggleSelectedLayerFromId,
    currentLayerId,
    setCurrentLayerWithFeatures,
  } = useLayers()
  const [currentEdit, setCurrentEdit] = useState(null)
  const formRef = useRef()
  const addRef = useRef()

  const { currentTool, setCurrentTool } = useToolbar()

  const onRowReorder = (e) => {
    setLayers(e.value)
  }

  const header = (
    <div className="table-header p-d-flex p-jc-between p-ai-center">
      Map layers
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-plus"
        onClick={(e) => {
          addRef.current.toggle(e)
        }}
      ></Button>
    </div>
  )

  return (
    <div className="w-100">
      <div className="card">
        <DataTable
          autoLayout
          header={header}
          value={layers}
          className="p-datatable-sm datatable-no-header"
          onRowReorder={onRowReorder}
          rowClassName={data => {
            return {
              [S['layer-selected']]: data.id === currentLayerId
          }
          }}
        >
          <Column rowReorder style={{ width: '3em' }} />
          <Column field="type"  body={(rowdata) => (
            <span onClick={() => toggleSelectedLayerFromId(rowdata.id)}>{rowdata.type}</span>
          )}></Column>
          <Column
            body={(rowdata) => (
              <>
                <Button
                  className="p-button-sm p-button-text"
                  icon="pi pi-external-link"
                  title="zoom to layer extent"
                  onClick={(e) => {
                    console.log('should fit..', rowdata.id)
                    centerLayer(rowdata.id)
                  }}
                />
                <Button
                  className="p-button-sm p-button-text"
                  icon="pi pi-cog"
                  title="configure layer"
                  onClick={(e) => {
                    formRef.current.toggle(e)
                    setCurrentEdit(rowdata)
                  }}
                />
                <Button
                  className={`p-button-sm p-button-text ${
                    rowdata.options.visible === false ? 'p-button-plain' : ''
                  }`}
                  icon={`pi ${
                    rowdata.options.visible === false
                      ? 'pi-eye-slash'
                      : 'pi-eye'
                  }`}
                  title="show/hide layer"
                  onClick={(e) => {
                    toggleLayerVisible(rowdata.id)
                  }}
                />
              </>
            )}
          ></Column>
          <Column
            body={(rowdata) => (
              <>
                <Button
                  className={`p-button-sm p-button-text`}
                  icon={`pi pi-trash`}
                  title="remove layer"
                  onClick={(e) => {
                    confirmPopup({
                      target: e.currentTarget,
                      message: 'Are you sure you want to proceed?',
                      icon: 'pi pi-exclamation-triangle',
                      accept: () => {
                        removeLayer(rowdata.id)
                      },
                      reject: () => {},
                    })
                  }}
                />
              </>
            )}
          ></Column>
        </DataTable>
      </div>

      <OverlayPanel ref={addRef}>
        <div className="p-flex-column">
          <div className="p-my-2">
            <Button
              className="p-button-sm w-100"
              label="Add OSM layer"
              onClick={(e) => {
                addLayer({
                  type: 'osm',
                  options: { opacity: 1 },
                  id: uuidv4(),
                })
                addRef.current.hide()
              }}
            ></Button>
          </div>
          <div className="p-my-2">
            <Button
              className="p-button-sm w-100"
              label="Add Stamen layer"
              onClick={(e) => {
                addLayer({
                  type: 'stamen',
                  options: { opacity: 1, layer: 'toner' },
                  id: uuidv4(),
                })
                addRef.current.hide()
              }}
            ></Button>
          </div>
          <div className="p-my-2">
            <Button
              className="p-button-sm w-100"
              label="Add geojson layer"
              onClick={(e) => {
                
                addLayerWithDialog({
                  type: 'geojson',
                  options: { opacity: 1, url: '' },
                  id: uuidv4(),
                })
                addRef.current.hide()
              }}
            ></Button>
          </div>
          <div className="p-my-2">
            <Button
              className="p-button-sm w-100"
              label="Add shapefile layer"
              onClick={(e) => {
                
                addLayerWithDialog({
                  type: 'shp',
                  options: { opacity: 1, url: '' },
                  id: uuidv4(),
                })
                addRef.current.hide()
              }}
            ></Button>
          </div>
        </div>
      </OverlayPanel>

      <OverlayPanel
        ref={formRef}
        dismissable={false}
        showCloseIcon
        onHide={() => {}}
      >
        {!!currentEdit && (
          <FormWrapper
            type={currentEdit.type}
            options={currentEdit.options}
            onSubmit={(values) => {
              console.log(123, values)
              updateLayer(currentEdit.id, values)
              formRef.current.toggle()
              setCurrentEdit(null)
            }}
          ></FormWrapper>
        )}
      </OverlayPanel>
    </div>
  )
}
